import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Weather from "./weatherByCityChilds/weather";
import Forecast from "./weatherByCityChilds/forecast";
import _ from "lodash";
import { Helmet } from "react-helmet";

export default function App(props) {

  
  const params = useParams();

  const parts = params.cityId.split("-");
  const loc = parts.pop();
  var cityName = parts.join(" ");

  cityName = _.startCase(_.toLower(cityName));

  var keywords =
    cityName +
    ", previsão do tempo " +
    cityName +
    ",clima para amanhã " +
    cityName +
    ", clima tempo " +
    cityName +
    ", clima de amanhã, clima para amanhã, clima amanhã, clima hoje, clima agora, previsão do tempo para amanhã, previsão do tempo amanhã, tempo para amanhã, o tempo, tempo hoje,";

  var description =
    "Obtenha a previsão do tempo mais precisa e atualizada para amanhã com nosso abrangente serviço clima para amanhã para " +
    cityName +
    ". Desde temperatura, precipitação e mais.";

  const input = loc;

  function imageDescription() {
    
    if (parts.includes("br")) {
      return "/img/clima_br.png";
    }

    if (parts.includes("ao")) {
      return "/img/clima_ao.png";
    }

    if (parts.includes("pt")) {
      return "/img/clima_pt.png";
    }
  }


  document.querySelector('meta[name="description"]').setAttribute("content", description);
  document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
  document.querySelector('meta[property="og:image"]').setAttribute("content", imageDescription());

  return (
    <div> <Helmet>
          <title>Clima em {cityName} - Hoje 🌡️🌤️ | Proximas 48h</title>
        </Helmet>
      <div className="main">
       

        <Header />

        <br />

        <h4 className="col-12 fw-normal" style={{ textAlign: "center" }}>
          Tempo Nas Próximas 48h Para {cityName}
        </h4>

        <br />
        <br />
        <div
          className="shadow p-3 mb-5 bg-body-tertiary rounded border accordion accordion-flush"
          id="accordionFlushExample"
        >
          <Weather id={input} />

          <small className="col-12">Próximas 48 horas</small>
          <Forecast id={input} />
        </div>

        <br />

        <div style={{ textAlign: "justify" }}>
          <br />
          <h5>Como o clima é calculado para {cityName}?</h5>
          <br />
          <p>
            O clima é o conjunto das condições atmosféricas de uma região ao
            longo dos anos. Ele varia conforme os elementos e fatores climáticos
            que atuam na atmosfera terrestre. Alguns dos principais elementos
            climáticos são a temperatura, a umidade, a radiação e a pressão
            atmosférica. Já os principais fatores climáticos são a latitude, a
            altitude, as massas de ar, as correntes marítimas, a
            continentalidade e a maritimidade.
          </p>

          <p>
            Para calcular o clima de {cityName}, é preciso observar e medir
            esses elementos e fatores ao longo de um longo período de tempo,
            geralmente 30 anos ou mais. Esses dados são coletados por estações
            meteorológicas, que podem ser automáticas ou convencionais,
            instaladas em diferentes pontos do território nacional. As estações
            meteorológicas registram informações como a temperatura máxima e
            mínima, a precipitação (chuva ou neve), a umidade relativa do ar, a
            velocidade e direção do vento, a radiação solar e outros parâmetros.
          </p>
          <p>
            Essas informações são enviadas para centros de previsão climática,
            que utilizam modelos matemáticos e computacionais para analisar as
            tendências e padrões climáticos de cada região. Esses modelos levam
            em conta as características geográficas de {cityName}, como sua
            localização em relação ao Equador e aos oceanos, sua altitude em
            relação ao nível do mar, seu relevo e sua vegetação. Além disso, os
            modelos também consideram os fenômenos climáticos que podem
            influenciar o clima local, como El Niño, La Niña, oscilação decadal
            do Pacífico etc.
          </p>
          <p>
            Com base nessas análises, os centros de previsão climática podem
            calcular o clima médio de {cityName} para cada mês ou estação do
            ano. O clima médio é uma estimativa baseada nos valores médios dos
            elementos climáticos observados no passado. Por exemplo: se em
            janeiro de 2020 a temperatura média em São Paulo foi de 24°C; em
            janeiro de 2021 foi de 25°C; e em janeiro de 2022 foi de 23°C; então
            o clima médio para janeiro na {cityName} é de 24°C.
          </p>
          <p>
            No entanto, o clima médio não representa exatamente o que acontece
            na realidade. O clima é dinâmico e pode sofrer variações temporárias
            ou permanentes por causa das mudanças naturais ou antrópicas na
            atmosfera terrestre. Por isso, além do clima médio, também se
            calcula o desvio padrão do clima (ou variabilidade climática), que
            indica o quanto os valores reais podem se afastar da média esperada.
          </p>
          <p>
            Assim sendo: calcular o clima para {cityName} envolve coletar dados
            históricos dos elementos climáticos; analisar os fatores geográficos
            e fenômenos atmosféricos que influenciam o clima local; aplicar
            modelos matemáticos e computacionais para estimar o clima médio; e
            avaliar o desvio padrão do clima para indicar sua variabilidade.
          </p>
          <h5>
            Você já se perguntou como é feita a previsão do clima para{" "}
            {cityName}{" "}
          </h5>

          <p>
            Como os meteorologistas sabem se vai chover ou fazer sol nos
            próximos dias?{" "}
          </p>
          <p>
            O clima é a caracterização das condições atmosféricas de uma
            determinada região através de registros de um longo período de
            tempo. Estas condições incluem temperatura, chuva, pressão
            atmosférica, umidade do ar e vento, por exemplo. O clima pode variar
            de acordo com fatores naturais ou antrópicos (relacionados à ação
            humana), como a topografia, a vegetação, os oceanos e as emissões de
            gases de efeito estufa.
          </p>
          <p>
            Para monitorar o clima, são usadas diversas ferramentas que coletam
            dados sobre o estado da atmosfera em diferentes pontos do planeta.
            Algumas dessas ferramentas são:
          </p>
          <ul>
            <li>
              - Estações meteorológicas: são equipamentos instalados em locais
              estratégicos que medem variáveis como temperatura, precipitação,
              pressão atmosférica e umidade relativa do ar. Existem estações
              automáticas e convencionais (que requerem observadores humanos).
            </li>

            <li>
              - Satélites: são dispositivos que orbitam ao redor da Terra e
              captam imagens e informações sobre as nuvens, a radiação solar, a
              temperatura da superfície e outros aspectos meteorológicos.
            </li>
            <li>
              - Radares: são antenas que emitem ondas eletromagnéticas que
              refletem nas partículas presentes na atmosfera (como gotas de
              chuva ou granizo) e permitem estimar a intensidade e a localização
              da precipitação.
            </li>
          </ul>
          <p>
            Todos esses dados são armazenados em bancos de dados que podem ser
            acessados por instituições responsáveis pela previsão do tempo. No
            Brasil, alguns desses institutos são o Instituto Nacional de
            Meteorologia (INMET), o Centro de Previsão de Tempo e Estudos
            Climáticos (CPTEC) do Instituto Nacional de Pesquisas Espaciais
            (INPE) e o Sistema Meteorológico do Paraná (SIMEPAR).
          </p>
          <p>
            Para fazer a previsão do tempo para {cityName}, os meteorologistas
            utilizam modelos numéricos que simulam o comportamento da atmosfera
            com base em equações matemáticas complexas. Esses modelos requerem
            uma grande capacidade computacional para processar os dados
            observados e gerar cenários futuros para diferentes variáveis
            climáticas.
          </p>
          <p>
            Os modelos numéricos podem ter diferentes resoluções espaciais (ou
            seja, o tamanho das áreas consideradas na simulação) e temporais (ou
            seja, o intervalo entre as previsões). Quanto maior a resolução
            espacial, mais detalhada é a previsão para uma determinada região.
            Quanto maior a resolução temporal, mais atualizada é a previsão para
            um determinado momento.
          </p>
          <p>
            Além dos modelos numéricos, os meteorologistas também usam técnicas
            estatísticas que relacionam os dados históricos com os atuais para
            estimar tendências climáticas. Essas técnicas podem ser úteis para
            prever eventos extremos ou sazonais.
          </p>
          <p>
            A previsão do tempo para {cityName} pode ser consultada em diversos
            meios de comunicação como sites na internet , aplicativos para
            celular, jornais impressos ou televisivos. A previsão pode ser
            apresentada em forma de mapas, gráficos, tabelas ou textos
            descritivos.
          </p>
          <p>
            A previsão do tempo para {cityName} é importante para auxiliar na
            tomada de decisões pessoais ou profissionais relacionadas às
            atividades ao ar livre. Por exemplo: planejar uma viagem turística;
            escolher as roupas adequadas; programar atividades agrícolas; evitar
            riscos à saúde; entre outras.
          </p>
          <p>
            No entanto, vale lembrar que a previsão do tempo não é uma certeza
            absoluta. Ela está sujeita a erros ou incertezas decorrentes da
            complexidade dos fenômenos atmosféricos e das limitações dos métodos
            utilizados.
          </p>
          <h5>
            Você já se perguntou como é feita a previsão do clima para amanhã de{" "}
            {cityName}?
          </h5>
          <p>
            A previsão do clima é um processo complexo que envolve várias
            etapas:
          </p>
          <ol>
            <li>
              Coleta de dados: As estações meteorológicas espalhadas pelo mundo
              medem variáveis como temperatura, pressão, umidade, vento e
              precipitação. Esses dados são enviados para centros de
              processamento que os organizam e os distribuem para diferentes
              instituições e agências.
            </li>
            <li>
              Análise dos dados: Os meteorologistas analisam os dados coletados
              e os comparam com as observações anteriores e as tendências
              climáticas. Eles também usam imagens de satélites e radares para
              visualizar as nuvens, as frentes frias e quentes, as áreas de
              baixa e alta pressão e outros fenômenos.
            </li>

            <li>
              Modelagem numérica: Os meteorologistas usam equações matemáticas
              que descrevem o comportamento da atmosfera para criar modelos
              numéricos que simulam o seu estado atual e futuro. Esses modelos
              são executados em supercomputadores que fazem milhões de cálculos
              por segundo.
            </li>

            <li>
              Interpretação dos resultados: Os meteorologistas avaliam os
              resultados dos modelos numéricos e os ajustam com base em sua
              experiência e conhecimento. Eles também consideram fatores como a
              topografia, a vegetação, a urbanização e a influência dos oceanos
              que podem afetar o clima local.
            </li>

            <li>
              Comunicação da previsão: Os meteorologistas elaboram a previsão do
              clima para diferentes períodos (curto, médio ou longo prazo) e
              regiões (local, regional ou global). Eles usam gráficos, mapas,
              tabelas e textos para comunicar a previsão ao público em geral ou
              a setores específicos como agricultura, aviação ou energia.
            </li>
          </ol>

          <p>
            A previsão do clima é uma ciência que está em constante evolução e
            melhoria. A cada dia, novos dados são coletados, novos modelos são
            desenvolvidos e novas técnicas são aplicadas. Por isso, é importante
            acompanhar as atualizações da previsão do clima para planejar suas
            atividades com mais segurança e conforto.
          </p>
        </div>

        <Footer />
      </div>
    </div>
  );
}
