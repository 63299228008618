import React, { useState } from "react";

var counter = 0;

export default function Weather(props) {
  const [weatherD, setWeatherD] = useState([]);
  const input = props.id;

  const cityForecastUrl =
    "https://api.openweathermap.org/data/2.5/forecast?id=" +
    input +
    "&appid=" +"28438ba7428003dc2cc2714b3a196d95" +
    "&units=metric&cnt=18&lang=pt_br";

  if (weatherD.length === 0 && counter < 2) {
    counter++;
    //cityDataCount++;
    // loading city data
    fetch(cityForecastUrl) //api for the get request
      .then((response) => response.json())
      .then((data) => {
        //console.log(data.list);
        setWeatherD(data.list);
      });
  }

  return (
    <div>
      {weatherD.map((item, index) => {
        return (
          <div key={index} className="border border-info-subtle">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-heading0">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#flush-collapse" + index}
                  aria-expanded="false"
                  aria-controls="flush-collapse0"
                >
                  <div className="container">
                    <div className="row justify-content-center align-items-center g-2">
                      <div className="col-12">
                        <strong>{item.dt_txt} </strong>
                      </div>
                      <div className="col">{item.weather[0].description}</div>
                      <div className="col">
                        {item.main.humidity}% de Humidade
                      </div>
                      <div className="col">
                        {item.main.temp}°C
                        <img
                          style={{
                            backgroundColor: "#0d6dfd7e",
                            borderRadius: "5px",
                          }}
                          alt="clima tempo"
                          height="35px"
                          src={
                            "https://openweathermap.org/img/wn/" +
                            item.weather[0].icon +
                            "@2x.png"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </button>
              </h2>
              <div
                id={"flush-collapse" + index}
                className="accordion-collapse collapse"
                aria-labelledby="flush-heading0"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body border border-primary-subtle">
                  <div className="row justify-content-center align-items-center g-2">
                    <div className="col-12">
                      Parece:
                      <strong>{item.main.feels_like}°C</strong>
                    </div>
                    <div className="col-12">
                      Pressão:
                      <strong>{item.main.pressure}mb</strong>
                    </div>
                    <div className="col-12">
                      Cobertura de Nuvens:
                      <strong>{item.clouds.all}%</strong>
                    </div>
                  </div>
                  <div className="row justify-content-center align-items-center g-2">
                    <div className="col-12">
                      Rajadas de Vento:
                      <strong>{item.wind.speed}m/s</strong>
                    </div>
                    <div className="col-12">
                      visibilidade:
                      <strong>{item.visibility / 1000}km</strong>
                    </div>
                    <div className="col"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
