import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import { Helmet } from "react-helmet";
const SearchCities = React.lazy(() => import("./searchCities"));


export default function App(props) {
  const [cities, setCities] = useState([]);

  async function getCity(city) {
    const url =
      "https://api.openweathermap.org/data/2.5/weather?q=" +
      city +
      "&appid=" +"28438ba7428003dc2cc2714b3a196d95"+
      "&units=metric&lang=pt_br";

    await fetch(url) //api for the get request
      .then((response) => response.json())
      .then((data) => {
        setCities([...cities, data]);
      });
  }


  setTimeout(()=>{

    
    if (cities.length === 0) {
      getCity("Luanda");
    }
  
    if (cities.length === 1) {
      getCity("Lisbon");
    }
  
    if (cities.length === 2) {
      getCity("Brasilia");
    }
    if (cities.length === 3) {
      getCity("Maputo");
    }
     
    

  },3000)



  //console.log(cities);
  return (
    <>
      
      <div className="main">
        <Header />
        <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
          <h3 className="display-6 fw-normal">Prever Tempo das Próximas 48h</h3>
          <small>
            Pesquisa a tua cidade no campo abaixo para prever o tempo das
            próximas 48 horas.
          </small>
          <br />

          <br />
          <SearchCities />
        </div>
        <div style={{ texAlign: "center" }}>
          <span>Pesquisas Populares</span>
          <br />
          <a
            className="col-4 btn btn-light border border-light-subtle"
            href="/previsaodotempo/sao-paulo-br-3448439"
          >
            SP (São Paulo)
          </a>
          <a
            className="col-4 btn btn-light border border-light-subtle"
            href="/previsaodotempo/rio-de-janeiro-br-3451190"
          >
            RJ (Rio de Janeiro)
          </a>
          <a
            className="col-4 btn btn-light border border-light-subtle"
            href="/previsaodotempo/curitiba-br-6322752"
          >
            Curitiba
          </a>
          <a
            className="col-4 btn btn-light border border-light-subtle"
            href="/previsaodotempo/porto-alegre-br-3452925"
          >
            Porto Alegre
          </a>
          <a
            className="col-4 btn btn-light border border-light-subtle"
            href="/previsaodotempo/praia-grande-br-3452465"
          >
            Praia Grande
          </a>
          <a
            className="col-4 btn btn-light border border-light-subtle"
            href="/previsaodotempo/salvador-br-6321026"
          >
            Salvador
          </a>
        </div>
        <br />

        <br />
        <div className="row">
          <div className="col-sm-6 col-md-3 col-lg-3">
            <div className="card" style={{ width: "18rem" }}>
              <h3
                style={{
                  textAlign: "center",
                  backgroundColor: "#0d6efd",
                  color: "white",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
              >
                {cities[0] !== undefined ? cities[0].name : "..."}
              </h3>
              <div
                style={{
                  paddingRight: "30%",
                  paddingLeft: "30%",
                  paddingTop: "10%",
                }}
              >
                <img
                  style={{
                    height: "100px",
                    width: "100px",
                    backgroundColor: "#0d6dfd57",
                  }}
                  src={
                    cities[0] !== undefined
                      ? "https://openweathermap.org/img/wn/" +
                        cities[0].weather[0].icon +
                        "@2x.png"
                      : require("../img/loading.gif")
                  }
                  className="card-img-top"
                  alt="clima"
                />
              </div>
              <div className="card-body" style={{ textAlign: "center" }}>
                <h2 className="card-title pricing-card-title">
                  {cities[0] !== undefined ? cities[0].main.temp : "..."}°C
                </h2>
                <small className="text-muted fw-light">Hoje (Tempo Real)</small>
                <ul className="list-unstyled mt-3 mb-4">
                  <li></li>
                  <br />
                  <li>
                    Descrição:{" "}
                    <strong>
                      {cities[0] !== undefined
                        ? cities[0].weather[0].description
                        : "..."}
                    </strong>
                  </li>
                  <li>
                    Parece:{" "}
                    <strong>
                      {cities[0] !== undefined
                        ? cities[0].main.feels_like
                        : "..."}
                      °C
                    </strong>
                  </li>
                  <li>
                    Pressão:{" "}
                    <strong>
                      {cities[0] !== undefined
                        ? cities[0].main.pressure
                        : "..."}
                      mb
                    </strong>
                  </li>
                  <li>
                    Humidade:{" "}
                    <strong>
                      {cities[0] !== undefined
                        ? cities[0].main.humidity
                        : "..."}
                      %
                    </strong>
                  </li>
                  <li>
                    Cobertura de Nuvens:{" "}
                    <strong>
                      {cities[0] !== undefined ? cities[0].clouds.all : "..."}%
                    </strong>
                  </li>
                  <li>
                    Rajadas de Vento:{" "}
                    <strong>
                      {cities[0] !== undefined ? cities[0].wind.speed : "..."}
                      m/s
                    </strong>
                  </li>
                  <li>
                    visibilidade:{" "}
                    <strong>
                      {cities[0] !== undefined
                        ? cities[0].visibility / 1000
                        : "..."}
                      km
                    </strong>
                  </li>
                  <li>
                    <strong>
                      Coordenadas
                      <br />{" "}
                    </strong>{" "}
                    lon:{" "}
                    <strong>
                      {cities[0] !== undefined ? cities[0].coord.lon : "..."},{" "}
                    </strong>
                    lat:{" "}
                    <strong>
                      {cities[0] !== undefined ? cities[0].coord.lat : "..."}
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3">
            <div className="card" style={{ width: "18rem" }}>
              <h3
                style={{
                  textAlign: "center",
                  backgroundColor: "#0d6efd",
                  color: "white",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
              >
                {cities[1] !== undefined ? cities[1].name : "..."}
              </h3>
              <div
                style={{
                  paddingRight: "30%",
                  paddingLeft: "30%",
                  paddingTop: "10%",
                }}
              >
                <img
                  style={{
                    height: "100px",
                    width: "100px",
                    backgroundColor: "#0d6dfd57",
                  }}
                  src={
                    cities[1] !== undefined
                      ? "https://openweathermap.org/img/wn/" +
                        cities[1].weather[0].icon +
                        "@2x.png"
                      : require("../img/loading.gif")
                  }
                  className="card-img-top"
                  alt="clima"
                />
              </div>
              <div className="card-body" style={{ textAlign: "center" }}>
                <h2 className="card-title pricing-card-title">
                  {cities[1] !== undefined ? cities[1].main.temp : "..."}°C
                </h2>
                <small className="text-muted fw-light">Hoje (Tempo Real)</small>
                <ul className="list-unstyled mt-3 mb-4">
                  <li></li>
                  <br />
                  <li>
                    Descrição:{" "}
                    <strong>
                      {cities[1] !== undefined
                        ? cities[1].weather[0].description
                        : "..."}
                    </strong>
                  </li>
                  <li>
                    Parece:{" "}
                    <strong>
                      {cities[1] !== undefined
                        ? cities[1].main.feels_like
                        : "..."}
                      °C
                    </strong>
                  </li>
                  <li>
                    Pressão:{" "}
                    <strong>
                      {cities[1] !== undefined
                        ? cities[1].main.pressure
                        : "..."}
                      mb
                    </strong>
                  </li>
                  <li>
                    Humidade:{" "}
                    <strong>
                      {cities[1] !== undefined
                        ? cities[1].main.humidity
                        : "..."}
                      %
                    </strong>
                  </li>
                  <li>
                    Cobertura de Nuvens:{" "}
                    <strong>
                      {cities[1] !== undefined ? cities[1].clouds.all : "..."}%
                    </strong>
                  </li>
                  <li>
                    Rajadas de Vento:{" "}
                    <strong>
                      {cities[1] !== undefined ? cities[1].wind.speed : "..."}
                      m/s
                    </strong>
                  </li>
                  <li>
                    visibilidade:{" "}
                    <strong>
                      {cities[1] !== undefined
                        ? cities[1].visibility / 1000
                        : "..."}
                      km
                    </strong>
                  </li>
                  <li>
                    <strong>
                      Coordenadas
                      <br />{" "}
                    </strong>{" "}
                    lon:{" "}
                    <strong>
                      {cities[1] !== undefined ? cities[1].coord.lon : "..."},{" "}
                    </strong>
                    lat:{" "}
                    <strong>
                      {cities[1] !== undefined ? cities[1].coord.lat : "..."}
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3">
            <div className="card" style={{ width: "18rem" }}>
              <h3
                style={{
                  textAlign: "center",
                  backgroundColor: "#0d6efd",
                  color: "white",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
              >
                {cities[2] !== undefined ? cities[2].name : "..."}
              </h3>
              <div
                style={{
                  paddingRight: "30%",
                  paddingLeft: "30%",
                  paddingTop: "10%",
                }}
              >
                <img
                  style={{
                    height: "100px",
                    width: "100px",
                    backgroundColor: "#0d6dfd57",
                  }}
                  src={
                    cities[2] !== undefined
                      ? "https://openweathermap.org/img/wn/" +
                        cities[2].weather[0].icon +
                        "@2x.png"
                      : require("../img/loading.gif")
                  }
                  className="card-img-top"
                  alt="clima"
                />
              </div>
              <div className="card-body" style={{ textAlign: "center" }}>
                <h2 className="card-title pricing-card-title">
                  {cities[2] !== undefined ? cities[2].main.temp : "..."}°C
                </h2>
                <small className="text-muted fw-light">Hoje (Tempo Real)</small>
                <ul className="list-unstyled mt-3 mb-4">
                  <li></li>
                  <br />
                  <li>
                    Descrição:{" "}
                    <strong>
                      {cities[2] !== undefined
                        ? cities[2].weather[0].description
                        : "..."}
                    </strong>
                  </li>
                  <li>
                    Parece:{" "}
                    <strong>
                      {cities[2] !== undefined
                        ? cities[2].main.feels_like
                        : "..."}
                      °C
                    </strong>
                  </li>
                  <li>
                    Pressão:{" "}
                    <strong>
                      {cities[2] !== undefined
                        ? cities[2].main.pressure
                        : "..."}
                      mb
                    </strong>
                  </li>
                  <li>
                    Humidade:{" "}
                    <strong>
                      {cities[2] !== undefined
                        ? cities[2].main.humidity
                        : "..."}
                      %
                    </strong>
                  </li>
                  <li>
                    Cobertura de Nuvens:{" "}
                    <strong>
                      {cities[2] !== undefined ? cities[2].clouds.all : "..."}%
                    </strong>
                  </li>
                  <li>
                    Rajadas de Vento:{" "}
                    <strong>
                      {cities[2] !== undefined ? cities[2].wind.speed : "..."}
                      m/s
                    </strong>
                  </li>
                  <li>
                    visibilidade:{" "}
                    <strong>
                      {cities[2] !== undefined
                        ? cities[2].visibility / 1000
                        : "..."}
                      km
                    </strong>
                  </li>
                  <li>
                    <strong>
                      Coordenadas
                      <br />{" "}
                    </strong>{" "}
                    lon:{" "}
                    <strong>
                      {cities[2] !== undefined ? cities[2].coord.lon : "..."},{" "}
                    </strong>
                    lat:{" "}
                    <strong>
                      {cities[2] !== undefined ? cities[2].coord.lat : "..."}
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3">
            <div className="card" style={{ width: "18rem" }}>
              <h3
                style={{
                  textAlign: "center",
                  backgroundColor: "#0d6efd",
                  color: "white",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
              >
                {cities[3] !== undefined ? cities[3].name : "..."}
              </h3>
              <div
                style={{
                  paddingRight: "30%",
                  paddingLeft: "30%",
                  paddingTop: "10%",
                }}
              >
                <img
                  style={{
                    height: "100px",
                    width: "100px",
                    backgroundColor: "#0d6dfd57",
                  }}
                  src={
                    cities[3] !== undefined
                      ? "https://openweathermap.org/img/wn/" +
                        cities[3].weather[0].icon +
                        "@2x.png"
                      : require("../img/loading.gif")
                  }
                  className="card-img-top"
                  alt="clima"
                />
              </div>
              <div className="card-body" style={{ textAlign: "center" }}>
                <h2 className="card-title pricing-card-title">
                  {cities[3] !== undefined ? cities[3].main.temp : "..."}°C
                </h2>
                <small className="text-muted fw-light">Hoje (Tempo Real)</small>
                <ul className="list-unstyled mt-3 mb-4">
                  <li></li>
                  <br />
                  <li>
                    Descrição:{" "}
                    <strong>
                      {cities[3] !== undefined
                        ? cities[3].weather[0].description
                        : "..."}
                    </strong>
                  </li>
                  <li>
                    Parece:{" "}
                    <strong>
                      {cities[3] !== undefined
                        ? cities[3].main.feels_like
                        : "..."}
                      °C
                    </strong>
                  </li>
                  <li>
                    Pressão:{" "}
                    <strong>
                      {cities[3] !== undefined
                        ? cities[3].main.pressure
                        : "..."}
                      mb
                    </strong>
                  </li>
                  <li>
                    Humidade:{" "}
                    <strong>
                      {cities[3] !== undefined
                        ? cities[3].main.humidity
                        : "..."}
                      %
                    </strong>
                  </li>
                  <li>
                    Cobertura de Nuvens:{" "}
                    <strong>
                      {cities[3] !== undefined ? cities[3].clouds.all : "..."}%
                    </strong>
                  </li>
                  <li>
                    Rajadas de Vento:{" "}
                    <strong>
                      {cities[3] !== undefined ? cities[3].wind.speed : "..."}
                      m/s
                    </strong>
                  </li>
                  <li>
                    visibilidade:{" "}
                    <strong>
                      {cities[3] !== undefined
                        ? cities[3].visibility / 1000
                        : "..."}
                      km
                    </strong>
                  </li>
                  <li>
                    <strong>
                      Coordenadas
                      <br />{" "}
                    </strong>{" "}
                    lon:{" "}
                    <strong>
                      {cities[3] !== undefined ? cities[3].coord.lon : "..."},{" "}
                    </strong>
                    lat:{" "}
                    <strong>
                      {cities[3] !== undefined ? cities[3].coord.lat : "..."}
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div style={{ textAlign: "justify" }}>
          <h5>Clima para amanhã: Previsões precisas para planejar seu dia</h5>

          <p>
            O clima é uma das coisas mais importantes que afetam nosso dia a
            dia, e saber o que esperar para o dia seguinte pode ajudar a
            planejar melhor as atividades do dia. As previsões meteorológicas
            para o dia seguinte são essenciais para a tomada de decisões
            diárias, desde escolher a roupa certa até planejar viagens e
            atividades ao ar livre. Neste artigo, vamos explorar como as
            previsões meteorológicas para amanhã são feitas, quais são as fontes
            mais confiáveis de informações climáticas e como interpretar as
            informações fornecidas.
          </p>

          <h5>Como as previsões meteorológicas para amanhã são feitas?</h5>

          <p>
            As previsões meteorológicas são feitas usando modelos matemáticos
            complexos que levam em consideração dados de satélites, observações
            de estações meteorológicas e outras informações climáticas. Esses
            modelos usam equações para prever como o clima mudará ao longo do
            tempo, com base em condições climáticas atuais e históricas. As
            previsões meteorológicas geralmente são feitas para um período de
            tempo específico, como 24 horas ou uma semana. Para fazer previsões
            para amanhã, os modelos usam as condições climáticas atuais e as
            comparam com dados históricos para fazer uma previsão para as
            próximas 24 horas.
          </p>

          <h5>Fontes confiáveis de informações climáticas</h5>

          <p>
            Existem muitas fontes de informações climáticas disponíveis, mas nem
            todas são igualmente confiáveis. As fontes mais confiáveis são as
            agências meteorológicas oficiais, como o Instituto Nacional de
            Meteorologia (INMET) no Brasil. Essas agências têm acesso a dados
            climáticos precisos e usam modelos meteorológicos avançados para
            produzir previsões precisas. Além das agências meteorológicas
            oficiais, existem muitos sites e aplicativos de previsão do tempo
            que fornecem informações climáticas. Esses sites e aplicativos
            geralmente usam os mesmos dados meteorológicos que as agências
            oficiais, mas podem fornecer previsões mais específicas para regiões
            ou cidades específicas.
          </p>

          <h5>Interpretando as informações climáticas</h5>

          <p>
            Ao interpretar as informações climáticas, é importante entender os
            termos usados nas previsões meteorológicas. Algumas das informações
            mais comuns incluem temperatura, precipitação, umidade, velocidade
            do vento e pressão atmosférica. A temperatura é a medida da
            intensidade do calor, geralmente medida em graus Celsius (°C) ou
            Fahrenheit (°F). A precipitação é a quantidade de água que cai do
            céu, geralmente medida em milímetros (mm) ou polegadas (in).
          </p>
          <p>
            A umidade é a quantidade de vapor de água na atmosfera, geralmente
            medida em porcentagem (%). A velocidade do vento é a velocidade do
            ar em movimento, geralmente medida em quilômetros por hora (km/h) ou
            milhas por hora (mph). A pressão atmosférica é a pressão exercida
            pela atmosfera na superfície da Terra, geralmente medida em
            hectopascais (hPa) ou milibares (mb).
          </p>

          <hr />
          <p>
            O clima é uma das coisas mais imprevisíveis do mundo, e isso pode
            ser particularmente verdadeiro quando se trata do clima para amanhã.
            É impossível prever com 100% de certeza o que o clima fará em
            qualquer dia específico, mas os meteorologistas têm técnicas e
            ferramentas que podem ajudá-los a fazer previsões precisas.
          </p>

          <p></p>

          <p>
            A previsão do tempo é uma ciência complexa, que envolve muitos
            fatores diferentes. Os meteorologistas estudam coisas como pressão
            atmosférica, umidade, temperatura, vento e padrões climáticos para
            ajudá-los a prever o que o clima fará em qualquer dia específico.
            Eles também usam modelos matemáticos complexos para ajudá-los a
            entender como esses fatores interagem e como eles afetarão o clima.
          </p>

          <p>
            Para a maioria das pessoas, a previsão do tempo para amanhã é uma
            das informações mais importantes que eles precisam saber. Se você
            planeja fazer uma viagem, planejar um evento ao ar livre ou
            simplesmente quer saber como se vestir, é importante ter uma ideia
            do que o clima fará. Felizmente, existem muitos lugares onde você
            pode encontrar previsões precisas para o clima para amanhã.
          </p>

          <p>
            Outra opção popular para obter informações sobre o clima para amanhã
            é assistir às notícias da televisão. A maioria das estações de
            notícias tem um meteorologista que apresenta o clima durante o
            noticiário. Esses meteorologistas geralmente têm acesso a
            informações mais recentes sobre o clima e podem fornecer previsões
            precisas para o dia seguinte.
          </p>

          <p>
            Se você não tiver acesso à internet ou à televisão, ainda há outras
            maneiras de obter informações sobre o clima para amanhã. Você pode
            ligar para uma estação de rádio local e pedir informações sobre o
            clima, ou pode ligar para a linha direta de previsão do tempo. Essas
            linhas diretas geralmente oferecem previsões atualizadas para o
            clima, e muitas vezes são gratuitas.
          </p>

          <p>
            Independentemente de onde você obtenha suas informações sobre o
            clima para amanhã, é importante lembrar que essas previsões são
            apenas isso - previsões. O clima é imprevisível e pode mudar
            rapidamente, então sempre esteja preparado para lidar com as
            condições climáticas que surgirem. Se você planeja sair de casa,
            certifique-se de levar roupas adequadas para o clima, um
            guarda-chuva ou um casaco impermeável, caso haja possibilidade de
            chuva.
          </p>
        </div>

        <Footer />
      </div>
    </>
  );
}
