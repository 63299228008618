import React, { useState } from "react";

var counter = 0;

export default function Weather(props) {
  const [weatherD, setWeatherD] = useState([]);
  const input = props.id;

  const cityIdUrl =
    "https://api.openweathermap.org/data/2.5/weather?id=" +
    input +
    "&appid=" +"28438ba7428003dc2cc2714b3a196d95"+
    "&units=metric&lang=pt_br";

  if (weatherD.length === 0 && counter < 2) {
    counter++;
    //cityDataCount++;
    // loading city data
    fetch(cityIdUrl) //api for the get request
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        setWeatherD(data);
      });
  }

  return (
    <div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="flush-heading2240444">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapse2240444"
            aria-expanded="false"
            aria-controls="flush-collapse2240444"
          >
            <div className="container">
              <div className="row justify-content-center align-items-center g-2">
                <div className="col-12">
                  <strong> Hoje Agora </strong>
                  <small>(Tempo Real)</small>
                </div>
                <div className="col">
                  {weatherD.length === 0
                    ? "..."
                    : weatherD.weather[0].description}
                </div>
                <div className="col">
                  {weatherD.length === 0 ? "..." : weatherD.main.humidity} % de
                  Humidade
                </div>
                <div className="col">
                  <strong style={{ fontSize: 20 }}>
                    {weatherD.length === 0 ? "..." : weatherD.main.temp}
                    °C{" "}
                  </strong>
                  <img
                    style={{
                      backgroundColor: "#0d6dfd7e",
                      borderRadius: "5px",
                      maxHeight: 50,
                      maxWidth: 50
                    }}
                    alt="clima tempo"
                    src={
                      weatherD.length === 0
                        ? require("../../img/loading.gif")
                        : "https://openweathermap.org/img/wn/" +
                          weatherD.weather[0].icon +
                          "@2x.png"
                    }
                  />
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div
          id="flush-collapse2240444"
          className="accordion-collapse collapse"
          aria-labelledby="flush-heading2240444"
          data-bs-parent="#accordionFlushExample"
        >
          <div className="accordion-body border border-primary-subtle">
            <div className="row justify-content-center align-items-center g-2">
              <div className="col-12">
                Parece:
                <strong>
                  {weatherD.length === 0 ? "..." : weatherD.main.feels_like} °C
                </strong>
              </div>
              <div className="col-12">
                Pressão:
                <strong>
                  {weatherD.length === 0 ? "..." : weatherD.main.pressure} mb
                </strong>
              </div>
              <div className="col-12">
                Cobertura de Nuvens:
                <strong>
                  {weatherD.length === 0 ? "..." : weatherD.clouds.all} %
                </strong>
              </div>
            </div>
            <div className="row justify-content-center align-items-center g-2">
              <div className="col-12">
                Rajadas de Vento:
                <strong>
                  {weatherD.length === 0 ? "..." : weatherD.wind.speed} m/s
                </strong>
              </div>
              <div className="col-12">
                visibilidade:
                <strong>
                  {weatherD.length === 0 ? "..." : weatherD.visibility / 1000}{" "}
                  km
                </strong>
              </div>
              <div className="col"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
