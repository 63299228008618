export default function Header() {
  return (
    <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
      <a
        href="/"
        className="d-flex align-items-center text-dark text-decoration-none"
      >
        <img
          height="60px"
          src={require("../img/main_icon.png")}
          alt=""
          style={{ marginRight: "5px" }}
        />

        <span className="fs-4" style={{ fontWeight: 500 }}>
          ClimaDeAmanha.com
        </span>
      </a>

      <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
        <a
          className="me-3 py-2 text-dark text-decoration-none"
          href="/tiempomanana"
          style={{ fontWeight: 500 }}
        >
          Tiempo Mañana (español)
        </a>
        <a
          className="me-3 py-2 text-dark text-decoration-none"
          href="https://delciotechnology.com/index.php/contact-us/"
          style={{ fontWeight: 500 }}
        >
          Contacta-Nos
        </a>
      </nav>
    </div>
  );
}
