import "./styles.css";
import Home from "./routes/home";
import WeatherByCity from "./routes/weatherByCity";
import TiempoManana from "./routes/tiempoManana";
import ErrorPage from "./routes/errorPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as React from "react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />
  },
  {
    path: "previsaodotempo/:cityId",
    element: <WeatherByCity />
  },
  {
    path: "tiempomanana",
    element: <TiempoManana />
  }
]);

export default function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
