export default function Footer() {
  return (
    <footer className="pt-4 my-md-5 pt-md-5 border-top">
      <div className="row">
        <div className="col-12 col-md" style={{ textAlign: "center" }}>
          <small className="d-block mb-3 text-muted">
            COPYRIGHT © 2024 CLIMA DE AMANHA. ALL RIGHTS RESERVED. - Developed
            by
            <a href="https://delciotechnology.com"> delciotechnology.com</a>
          </small>
        </div>
      </div>
    </footer>
  );
}
